import React, { useEffect, useState, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { formatDateStringDDMMYYYY } from "../../Common/functions";
import { useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import CommonButton from "../../Common/CommonButton";
import Loader from "../../Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import { LeaveReportApi } from "../../services/reports/LeaveReportApi";

const LeaveReport = forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterEmpId, setFilterEmpId] = useState('');
    const [loading, setLoading] = useState(false);
    const [allLeaves, setAllLeaves] = useState([]);
    const [error, setError] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const [excloading, setExcloading] = useState(false)
    const [lastRecordId, setLastRecordId] = useState('');
    const [hasMore, setHasMore] = useState(true);



    const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
    const accessToken = useSelector((state) => state?.accessToken?.accessToken);
    const designation = useSelector((state) => state?.userDetails?.details?.Designation__c);
    const division = useSelector((state) => state?.userDetails?.details?.Division__c);

    // const fetchData = async () => {
    //   setLoading(true);


    //   try {

    //     // Fetch data with pagination parameters
    //     const claimResponse = await reportDownloadApi(
    //       accessToken,
    //       filterEmpId,
    //       startDate,
    //       endDate,
    //       "Expense",
    //       division // Pass lastId to fetch the next batch of records
    //     );

    //     const dataArray = claimResponse.data.Expense;
    //     console.log("Fetched Data:", dataArray);

    //     // Exit loop if no more data is returned


    //     // Update lastId to the ID of the last fetched record
    //     // Adjust this according to your data structure


    //     // Filter and sort the accumulated data
    //     const start = startDate ? new Date(startDate) : new Date('1900-01-01');
    //     const end = endDate ? new Date(endDate) : new Date('2100-01-01');
    //     start.setHours(0, 0, 0, 0)
    //     end.setHours(23, 59, 59, 999)

    //     const filteredData = dataArray.filter(item => {
    //       const itemDate = new Date(item.Date__c || item?.From_Date__c || item?.To_Date__c || item?.DADate__c || item?.TA_Arrival_Date__c || item?.TA_Depature_Date__c);
    //       return filterEmpId ?
    //         (itemDate >= start && itemDate <= end && filterEmpId === item?.Contact__r?.EmpId__c) :
    //         (itemDate >= start && itemDate <= end);
    //     });
    //     console.log("filteredData", filteredData)

    //     const sortedData = filteredData.sort((a, b) => {
    //       const dateFieldsA = [a?.To_Date__c, a?.DADate__c, a?.TA_Depature_Date__c, a?.Date__c].map(date => new Date(date)).filter(date => !isNaN(date));
    //       const dateFieldsB = [b?.To_Date__c, b?.DADate__c, b?.TA_Depature_Date__c, b?.Date__c].map(date => new Date(date)).filter(date => !isNaN(date));
    //       const latestDateA = dateFieldsA.length > 0 ? new Date(Math.max(...dateFieldsA)) : new Date('1900-01-01');
    //       const latestDateB = dateFieldsB.length > 0 ? new Date(Math.max(...dateFieldsB)) : new Date('1900-01-01');
    //       return latestDateB - latestDateA;
    //     });
    //     console.log("sortedData", sortedData)
    //     setAllClaims(sortedData);
    //     setDataFetched(true)
    //   } catch (error) {
    //     console.error('Error fetching expense claims:', error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    const fetchData = async (loadMore = false, id = '') => {
        setLoading(true); // Start loading state
        try {
            // Fetch data from API, use the id (lastRecordId) when loading more
            const response = await LeaveReportApi(
                accessToken,
                filterEmpId,
                startDate,
                endDate,
                designation,
                EmployeeId,
                loadMore ? id : '',  // Pass the id (lastRecordId) if loading more
                division
            );

            // Parse the response data
            const dataArray = JSON.parse(response.data);
            console.log("API Response:", dataArray);

            // Check if data and records are available
            if (!dataArray || !dataArray.leaveRecords || dataArray.leaveRecords.length === 0) {
                setAllLeaves([]); // Reset data to an empty array
                setHasMore(false); // No more records to fetch
                return;
            }

            const { leaveRecords, lastRecordId } = dataArray;

            // Sort leave records by Leave_Start_Date__c in Descending order
            const sortedData = leaveRecords.sort((a, b) => {
                const dateA = new Date(a.Leave_Start_Date__c);
                const dateB = new Date(b.Leave_Start_Date__c);

                // Return the difference for Descending order
                return dateB - dateA;
            });

            // Log sorted data
            console.log("Sorted Records (Descending):", sortedData);

            if (loadMore) {
                // Append new sorted records to the existing data
                setAllLeaves((prevData) => [...prevData, ...sortedData]);
            } else {
                // Set sorted data for the first load
                setAllLeaves(sortedData);
            }

            // Update the lastRecordId for pagination
            setLastRecordId(lastRecordId);

            // If there are more records, allow loading more
            setHasMore(!!lastRecordId);

            // Set dataFetched based on the presence of data
            setDataFetched(sortedData.length > 0);
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error("Provided employee code is not a valid subordinate.");
                setAllLeaves([]); // Reset data to an empty array
            } else {
                console.error("Error fetching tour plans:", error);
                setAllLeaves([]); // Reset data to an empty array in case of other errors
            }
            setDataFetched(false); // Indicate no data was fetched in case of error
        } finally {
            setLoading(false); // End loading state
        }
    };




    const handleSubmit = () => {
        fetchData();
    };

    const handleLoadMore = () => {
        if (hasMore) {
            fetchData(true, lastRecordId); // Pass true for load more and the lastRecordId
        }
    };



    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
            setError('Please enter only numbers.');
        } else {
            setError('');
        }
    };

    const exportToExcel = async () => {
        let allData = [];  // Array to store all the fetched data
        let lastRecordId = '';  // Keep track of the last fetched record ID
        let hasMore = true;  // Boolean to indicate if more records are available


        setExcloading(true);  // Show loading indicator during export process

        try {
            // Loop until all data is fetched
            while (hasMore) {
                // Fetch a chunk of data
                const response = await LeaveReportApi(
                    accessToken,
                    filterEmpId,
                    startDate,
                    endDate,
                    designation,
                    EmployeeId,
                    lastRecordId,  // Use lastRecordId to fetch the next batch of records
                    division
                );

                // Parse the response
                const dataArray = JSON.parse(response.data);

                console.log("data array==", dataArray)

                // If no records are returned, stop fetching
                if (!dataArray || !dataArray.leaveRecords || dataArray.leaveRecords.length === 0) {
                    hasMore = false;
                    break;
                }

                // Extract records and the next lastRecordId from the response
                const { leaveRecords, lastRecordId: newLastRecordId } = dataArray;

                // Merge the new records into allData
                allData = [...allData, ...leaveRecords];

                // Update the lastRecordId for the next API call
                lastRecordId = newLastRecordId;

                // Check if there are more records to fetch
                hasMore = !!lastRecordId;
            }

            // Sort the allData array by Leave_Start_Date__c in ascending order before exporting to Excel
            const sortedData = allData.sort((a, b) => {
                const dateA = new Date(a.Leave_Start_Date__c);
                const dateB = new Date(b.Leave_Start_Date__c);
                return dateB - dateA;  // Ascending sort (oldest first)
            });

            console.log("sorted data00", sortedData)

            // After fetching and sorting all records, export to Excel
            if (sortedData.length > 0) {
                // Generate Excel file from sortedData
                handleExport(sortedData);
            } else {
                console.log('No data available to export');
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                toast.error("Provided employee code is not a valid subordinate.");
                setAllLeaves([]); // Reset data to an empty array
            } else {
                console.error("Error fetching tour plans:", error);
                setAllLeaves([]); // Reset data to an empty array in case of other errors
            }
        } finally {
            setExcloading(false);  // Hide loading indicator after export process
        }
    };






    const handleExport = (allLeaves) => {
        if (!allLeaves || allLeaves.length === 0) {
            alert('No data available to export');
            return;
        }

        // Convert data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(allLeaves.map(leave => ({
            'Application Date': formatDateStringDDMMYYYY(leave.Application_Date__c) || 'N/A',
            'Emp Code': leave.Employee__r.EmpId__c,
            'Leave Start Date': formatDateStringDDMMYYYY(leave.Leave_Start_Date__c) || 'N/A',
            'Leave Type': leave.Leave_Type__c || 'N/A',
            'Session 1': leave.Session_1__c || 'N/A',
            'Session 2': leave.Session_2__c || 'N/A',
            'Status': leave.Status__c || 'N/A',
        })));

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Leave Report");

        // Export the workbook
        XLSX.writeFile(workbook, "Leave_Report.xlsx");
    };

    return (
        <>
            {excloading && <Loader />}
            <div className="markAttendance_section container-fluid">
                <div className="row mb-5">
                    <Card className="monthlyClaim_mobile">
                        <Card.Body>
                            <h4 className="mb-4 text-center">Leave Report</h4>
                            <div className="filter-section mb-4">
                                <label style={{ marginRight: '1rem' }}>
                                    Start Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        value={startDate}
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        onChange={e => setStartDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    End Date
                                    <input
                                        className="CommonDropdown form-select"
                                        type="date"
                                        style={{
                                            width: "100%",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                    />
                                </label>
                                <label style={{ marginRight: '1rem' }}>
                                    EmpId
                                    <input
                                        className="CommonDropdown form-select"
                                        type="text"
                                        maxLength={4}
                                        style={{
                                            width: "8rem",
                                            color: "rgb(46, 49, 146)",
                                            fontSize: "14px",
                                            backgroundImage: "none",
                                            paddingRight: "14px",
                                        }}
                                        value={filterEmpId}
                                        onChange={e => setFilterEmpId(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                    />
                                </label>
                                <span onClick={handleSubmit}>
                                    <CommonButton title={"Submit"} className="btn btn-primary" />
                                </span>
                                {dataFetched && (

                                    <span style={{ marginLeft: "10px" }} onClick={exportToExcel}>
                                        <CommonButton title={'Download Excel'} className="btn btn-primary" />
                                    </span>
                                )}

                            </div>
                            {error && <span className="dcr-form-errors">{error}</span>}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                dataFetched && (
                                    <div
                                        id="table-to-xls"
                                        className="table-wrapper-scroll-y custom-scrollbFromar"
                                        style={{ padding: '20px', overflow: 'auto' }}
                                    >
                                        <table
                                            style={{ textAlign: "center", width: '120%' }}
                                            ref={ref}
                                        >
                                            <thead>
                                                <tr className="ladder_heading">
                                                    <th className="custom-header">Application Date</th>
                                                    <th className="custom-header">Emp Id</th>
                                                    <th className="custom-header">Leave Start Date</th>
                                                    <th className="custom-header">Leave Type</th>
                                                    <th className="custom-header">Session 1</th>
                                                    <th className="custom-header">Session 2</th>
                                                    <th className="custom-header">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allLeaves.map((leave, index) => {
                                                    return (
                                                        <tr key={index} className="ladder_heading_data">
                                                            <td className='pl-3'>{formatDateStringDDMMYYYY(leave.Application_Date__c)}</td>
                                                            <td className="pl-3">{leave?.Employee__r?.EmpId__c}</td>
                                                            <td className='pl-3'>{formatDateStringDDMMYYYY(leave.Leave_Start_Date__c)}</td>
                                                            <td className='pl-3'>{leave.Leave_Type__c}</td>
                                                            <td className='pl-3'>{leave.Session_1__c}</td>
                                                            <td className='pl-3'>{leave.Session_2__c}</td>
                                                            <td className='pl-3'>{leave.Status__c}</td>


                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            )}
                        </Card.Body>
                    </Card>
                    <ToastContainer />
                    {(hasMore && (allLeaves.length > 0)) &&
                        <span style={{ marginTop: "10px" }} onClick={handleLoadMore}>
                            <CommonButton title={"Load More"} />
                        </span>
                    }
                </div>
            </div>
        </>
    );
});

export default LeaveReport;