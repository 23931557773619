import React, { useEffect, useState, useRef } from "react";
import "./Reports.css";
import MultiColumnReport from "./MultiColumnReport";
import BackButton from "../../Common/BackButton";
import { useSelector } from "react-redux";
import { getTotalPobApi } from "../../services/home/getTotalPobApi";
import { getCallAverageApi } from "../../services/home/getCallAverageApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from '../../Common/Loader';
import { yearlyBudget } from "../../services/actualBudget";
import { actualBudgetApiTokenApi } from "../../services/actualBudgetApiTokenApi";
import GaugeChart from "../../Common/GaugeChart";
import FeedbackData from "../../Pages/FeedbackData";
import "../../Components/MonthlyTour/PlanMTP.css"
import TpReport from "./TpReport";
import DcrReport from "./DcrReport";
import AttendanceReport from "./AttendanceReport";
import VseDevReport from "./VseDevReport";
import AsmDevReport from "./AsmDevReport";
import CallAverageReport from "./CallAverageReport";
import ActualPlannedReport from "./ActualPlannedReport";
import ExpenseClaimReport from "./ExpenseClaimReport";
import JointWorkingReport from "./JointWorkingReport";
import CommonDropdown from "../../Common/CommonDropdown";
import SummaryReport from "./SummaryReport";
import LeaveReport from "./LeaveReport";

const Reports = () => {

  const EmployeeId = useSelector((state) => state?.userDetails?.details?.Id);
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const designation = useSelector((state) => state?.userDetails?.details?.Designation__c)
  const empId = useSelector((state) => state?.userDetails?.details?.EmpId__c);
  const salesTargetValue = useSelector((state) => state?.actualBudget?.actualValue);

  const [callCoverage, setCallCoverage] = useState([]);
  const [pobTarget, setPobTarget] = useState([]);
  const [loading, setLoading] = useState(false)
  const [targetValue, setTargetValue] = useState("");
  const [actualValue, setActualValue] = useState("");
  const [yearlyTargetValue, setYearlyTargetValue] = useState('');
  const [yearlyaActualValue, setYearlyActualValue] = useState('')
  const [reportType, setReportType] = useState('Dashboard')

  const tableAttendanceRef = useRef(null);
  const tableDcrRef = useRef(null);
  const tableExpenseRef = useRef(null);
  const tableTpref = useRef(null);
  const tableJointWorkRef = useRef(null);
  const tablePobRef = useRef(null);
  const tableVseDevRef = useRef(null);
  const tableRsmDevRef = useRef(null);
  const tableCallAvgRef = useState(null)

  const getCurrentMonthData = () => {
    const currentDate = new Date();
    const currentMonthName = currentDate.toLocaleString('default', { month: 'short' });
    const currentYear = currentDate.getFullYear(); // Get current year
    const totalDaysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const xAxis = [];
    let count = 0;

    let isMonthAdded = false;

    for (let day = 1; day <= totalDaysInMonth; day++) {
      const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      if (currentDay.getDay() !== 0) {
        if (!isMonthAdded) {
          xAxis.push(`${currentMonthName} ${currentYear}`);
          isMonthAdded = true;
        }
        count++;
      }
    }

    return {
      xAxis: xAxis,
      count: [count]
    };
  };

  const currentMonthData = getCurrentMonthData();
  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await getCallAverageApi(accessToken, EmployeeId);
      if (response?.status === 200) {
        setCallCoverage(response?.data);
      } else {
      }
      setLoading(false)
    })();
  }, [EmployeeId, accessToken, designation]);

  useEffect(() => {
    (async () => {
      const response = await getTotalPobApi(accessToken, EmployeeId);
      if (response?.status === 200) {
        setPobTarget(response?.data);
      } else {
      }
    })();
  }, [EmployeeId, accessToken]);

  useEffect(() => {
    (async () => {
      if (salesTargetValue) {
        const dataString = salesTargetValue?.split(" - ");
        const targetData = dataString?.[0]?.split(":")?.[1];
        const actualDataData = dataString?.[1]?.split(":")?.[1];
        const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
        const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
        setTargetValue(targetParsed);
        setActualValue(actualParsed);
      }
    })();
  }, [salesTargetValue]);


  useEffect(() => {
    (async () => {
      const responseToken = await actualBudgetApiTokenApi(accessToken);
      const response = await yearlyBudget(empId, responseToken);
      const values = response?.data?.value;
      const dataString = values?.split(" - ");
      const targetData = dataString?.[0]?.split(":")?.[1];
      const actualDataData = dataString?.[1]?.split(":")?.[1];
      const targetParsed = parseFloat(targetData?.split(",")?.join('')) || 0;
      const actualParsed = parseFloat(actualDataData?.split(",")?.join('')) || 0;
      setYearlyTargetValue(targetParsed);
      setYearlyActualValue(actualParsed);
    })();
  }, [])

  const handleActivityChange = (value) => {
    setReportType(value)
  };
  const allOptions = [
    "Attendance Report",
    "Tour Plan Report",
    "DCR Survey Report",
    "Joint Working Coverage",
    "Planned Vs Actual POB",
    "Call Average",
    "VSE Development Feedback",
    "ASM Development Feedback",
    "Expense Claim Report",
    "Leave Report",
    "Summary"
  ];

  const filteredOptions = allOptions.filter(option => {
    if (designation === "VSE" || designation === "BE") {
      return option !== "ASM Development Feedback" && option !== "Joint Working Coverage";
    } else {
      return true;
    }
  });

  return (
    <div>
      <div className="container">
        <div className="mt-3 ">
          <BackButton title={"Reports"} />
        </div>
        <div className="markAttendance Approve_plansTab_heading">

          <p className="mt-4 PlanMTP_leftHead" style={{ display: 'flex', justifyContent: 'left' }}>Type of Report</p>
          <CommonDropdown
            handleDropdownChange={handleActivityChange}
            className="PlanMTP_leftDrop"
            defaultOption={"Dashboard"}
            options={filteredOptions}
          />
          {/* <Tabs
            defaultActiveKey="dashboard"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          > */}
          {/* Dashboard */}
          {/* <Tab
              eventKey="dashboard"
              title="Dashboard"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "Dashboard" && (<>
            <div className="markAttendance_section container-fluid">
              <div className="row mb-5">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
                  <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Call Average</h4>
                  <MultiColumnReport
                    description={["Achieved", "Target"]}
                    xAxis={currentMonthData?.xAxis}
                    Total={[8]}
                    Achieved={[callCoverage?.callAverage]}
                    colors={["rgb(44, 175, 254)", "#4FC0D0"]}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
                  <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>POB(Actual Vs Achieved)</h4>
                  <MultiColumnReport
                    description={["Actual", "Target"]}
                    xAxis={currentMonthData?.xAxis}
                    Total={[pobTarget?.ActualPOB]}
                    Achieved={[pobTarget?.PlannedPOB]}
                    colors={["rgb(44, 175, 254)", "#4FC0D0"]}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
                  <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Budget Vs Actual</h4>
                  <GaugeChart
                    values={[targetValue, actualValue]}
                    title={"Target"}
                    P1={"Monthly target"}
                    P2={"Target achieved"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-3 mb-3 report_padding">
                  <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Budget Vs Actual (YTD)</h4>
                  <GaugeChart
                    values={[yearlyTargetValue, yearlyaActualValue]}
                    title={"Target"}
                    P1={"Yearly target"}
                    P2={"Target achieved"}
                  />
                </div>
              </div>
              <div className="row m-2 mt-4">
                <h4 className="mb-3" style={{ color: "#2E3192", fontFamily: 'GilroyBold' }}>Development Rating</h4>
                <FeedbackData
                  EmployeeId={EmployeeId}
                  accessToken={accessToken}
                />
              </div>
            </div>
          </>)}
          {/* </Tab> */}

          {/* Attendance Report */}
          {/* <Tab
              eventKey="attendance"
              title="Attendance Report"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "Attendance Report" && (<>
            <AttendanceReport ref={tableAttendanceRef} />
          </>)}
          {/* </Tab> */}

          {/* TP Report */}
          {/* <Tab
              eventKey="tourPlan"
              title="Tour Plan Report"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >*/}
          {reportType === "Tour Plan Report" && (<>
            <TpReport ref={tableTpref} />
          </>)}
          {/* </Tab>  */}

          {/* DCR Report */}
          {/* <Tab
              eventKey="dcrSurvey"
              title="DCR Survey Report"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "DCR Survey Report" && (<>
            <DcrReport ref={tableDcrRef} />
          </>)}
          {/* </Tab> */}

          {/* Joint Working Report */}
          {designation !== "VSE" && designation !== "BE" && (
            // <Tab
            //   eventKey="jointWorking"
            //   title="Joint Working Coverage"
            //   className="markAttendance_tabsSection mt-4"
            //   style={{ fontFamily: 'GilroyMedium' }}
            // >
            reportType === "Joint Working Coverage" && (
              <JointWorkingReport ref={tableJointWorkRef} />
            )
            // </Tab>
          )}

          {/* Actual Planned Report */}
          {/* <Tab
              eventKey="actualPlanned"
              title="Planned Vs Actual POB"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "Planned Vs Actual POB" && (<>
            <ActualPlannedReport ref={tablePobRef} />
          </>)}
          {/* </Tab> */}

          {/* Call Average Report */}
          {/* <Tab
              eventKey="callAverage"
              title="Call Average"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "Call Average" && (<>
            <CallAverageReport ref={tableCallAvgRef} />
          </>)}
          {/* </Tab> */}

          {/* Vse Development Report */}
          {/* <Tab
              eventKey="vseDevelopmet"
              title="VSE Development Feedback"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "VSE Development Feedback" && (<>
            <VseDevReport ref={tableVseDevRef} />
          </>)}
          {/* </Tab> */}

          {/* Asm development report */}
          {designation !== "VSE" && designation !== "BE" && (
            // <Tab
            //   eventKey="asmDevelopment"
            //   title="ASM Development Feedback"
            //   className="markAttendance_tabsSection mt-4"
            //   style={{ fontFamily: 'GilroyMedium' }}
            // >
            reportType === "ASM Development Feedback" && (
              <AsmDevReport ref={tableRsmDevRef} />
            )
            // </Tab>
          )}

          {/* Expense Report */}
          {/* <Tab
              eventKey="expenseClaim"
              title="Expense Claim Report"
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            > */}
          {reportType === "Expense Claim Report" && (<>
            <ExpenseClaimReport ref={tableExpenseRef} />
          </>)}

          {reportType === "Leave Report" && (<>
            <LeaveReport ref={tableExpenseRef} />
          </>)}

          {reportType === "Summary" && (
            <SummaryReport />
          )}
          {/* </Tab> */}
          {/* </Tabs> */}
        </div>
      </div>
      {loading && <Loader />}
    </div >
  );
};

export default Reports;
