import React, { useState, useRef, useEffect } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import "./UploadRoutes.css";
import CommonButton from '../Common/CommonButton';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Common/Loader';
import BackButton from "../Common/BackButton";
import upload from "../assets/images/file.png"
import { CreateEmployeeApi } from '../services/CreateEmp/CreateEmployeeApi';
import CommonDropdown from '../Common/CommonDropdown';


const CreateEmployeeComponent = () => {
    const [jsonData, setJsonData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState();
    const [fileError, setFileError] = useState('');
    const [responseData, setResponseData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const options = ['Active'];
    const divisionoptions = ['AH', 'PH', 'PET']
    const defaultDivisionOption = 'Select a Division';
    const defaultOption = 'Select an Employee Status';
    const defaultregoption = 'Select a Region';
    const regionoption = [
        'East India',
        'North India',
        'West India-1',
        'East India 2',
        'South India',
        'West India-2',
        'North',
        'South',
        'East',
        'APTGN',
        'N-India 1',
        'N-India 2',
        'KAR',
        'TN',
        'West India'
    ];



    const [formData, setFormData] = useState({
        Name: '',
        LastName: '',
        EmpId: '',
        Email: '',
        Phone: '',
        Division: '',
        HQ: '',
        SL: '',
        PL: '',
        CL: '',
        JoiningDate: '',
        Region: '',
        DOB: '',
        ReportTo: '',
        Designation: '',
        ReportingHq: '',
        State: '',
        Zone: '',
        probationperiod: '',
        EmployeeStatus: '',
    });

    const fieldLabels = {
        Name: 'First Name',
        LastName: 'Last Name',
        EmpId: 'Employee Id',
        Email: 'Email',
        Phone: 'Phone',
        Division: 'Division',
        HQ: 'HQ',
        SL: 'SL',
        PL: 'PL',
        CL: 'CL',
        JoiningDate: 'Joining Date',
        Region: 'Region',
        DOB: 'Date of Birth',
        ReportTo: 'Report To (Please enter employee Id)',
        Designation: 'Designation',
        ReportingHq: 'Reporting HQ',
        State: 'State',
        Zone: 'Zone',
        probationperiod: 'Probation Period',
        EmployeeStatus: 'Employee Status'
    };

    const accessToken = useSelector((state) => state?.accessToken?.accessToken);

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        setFileName(file.name);
        setFileError('');  // Clear the file error when a file is selected

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryStr = event.target?.result;
                if (binaryStr) {
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet);

                    const formattedData = {
                        Employees: data.map(item => ({
                            Name: item['First Name'] || '',
                            LastName: item['Last Name'] || '',
                            EmpId: item['Employee Id'] || '',
                            Email: item['Email'] || '',
                            Phone: String(item['Phone']) || '',
                            Division: item['Division'] || '',
                            HQ: item['HQ'] || '',
                            SL: item['SL'] || '',
                            PL: item['PL'] || '',
                            CL: item['CL'] || '',
                            JoiningDate: item['Joining Date'] ? formatExcelDate(item['Joining Date']) : '',
                            Region: item['Region'] || '',
                            DOB: item['Date of Birth'] ? formatExcelDate(item['Date of Birth']) : '',
                            ReportTo: item['Report To (Please enter employee Id)'] || '',
                            Designation: item['Designation'] || '',
                            ReportingHq: String(item['Reporting HQ']) || '',
                            State: item['State'] || '',
                            Zone: item['Zone'] || '',
                            probationperiod: item['Probation Period'] ? formatExcelDate(item['Probation Period']) : '',
                            EmployeeStatus: item['Employee Status'] || '',
                        }))
                    };

                    setJsonData(formattedData);
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const formatExcelDate = (excelDate) => {
        const utcDays = excelDate - 25569;
        const date = new Date(utcDays * 86400 * 1000);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}/${month}/${year}`;
    };

    const handleSubmit = async () => {
        if (!fileName) {
            setFileError("Please select a file before submitting");
            return;
        }

        setLoading(true);
        const response = await CreateEmployeeApi(accessToken, jsonData);

        if (response) {
            try {
                const parsedResponse = JSON.parse(response.data); // Parse the response data
                setResponseData(parsedResponse.employees || []); // Set the employees array to state
                toast.success("Data uploaded successfully");
            } catch (error) {
                toast.error("Error parsing response data.");
                console.error("Parsing error:", error);
            }
        } else {
            toast.error("An error occurred");
        }

        setLoading(false);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Allow only numeric input for specific fields
        if (name === 'empCode' || name === 'EmpId' || name === 'Phone' || name === 'RegionCode') {
            if (/^\d*$/.test(value)) { // Check if value is numeric
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = `${fieldLabels[key]} is required`;
            }
        });
        // Email validation
        if (formData.Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.Email)) {
            newErrors.Email = 'Invalid email format';
        }
        return newErrors;
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Transform form data into the required payload format
        const payload = {
            Employees: [formData]
        };

        setLoading(true);
        console.log('Form Data Submitted:', payload);

        try {
            const response = await CreateEmployeeApi(accessToken, payload);
            console.log("response =", response);

            // Try parsing the response data
            let parsedData;
            try {
                parsedData = JSON.parse(response.data);
            } catch (parseError) {
                toast.error('Failed to parse response data.');
                console.error('Parsing error:', parseError);
                return; // Early exit on parse error
            }

            // Check if parsed data has the correct format
            if (parsedData && parsedData.employees && parsedData.employees.length > 0) {
                const employeeResponse = parsedData.employees[0];
                if (employeeResponse.status === 'success') {
                    toast.success('Data submitted successfully');
                } else {
                    toast.error(employeeResponse.message || 'An error occurred during submission');
                }
            } else {
                toast.error('Unexpected response format.');
            }

        } catch (error) {
            toast.error('An error occurred while submitting the form.');
            console.error('Submission error:', error);
        }


        // Clear form after submission
        setFormData({
            Name: '',
            LastName: '',
            EmpId: '',
            Email: '',
            Phone: '',
            Division: '',
            HQ: '',
            SL: 'SL',
            PL: 'PL',
            CL: 'CL',
            JoiningDate: '',
            Region: '',
            DOB: '',
            ReportTo: '',
            Designation: '',
            ReportingHq: '',
            State: '',
            Zone: '',
            probationperiod: '',
            EmployeeStatus: '',
        });

        setErrors({});
        setLoading(false);
    };


    const handlePhotoIconClick = () => {
        fileInputRef.current.click();
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDownloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const headers = Object.keys(fieldLabels).map(key => fieldLabels[key]);
        const sampleRecord = [
            'akshay', // First Name
            'mahajan', // Last Name
            '9643', // Employee Id
            'akshay@gmail.com', // Email
            '7856489565', // Phone
            'AH', // Division
            'pune', // HQ
            '2', // SL
            '17', // PL
            '2', // CL
            '05/02/2024', // Joining Date
            'B', // Region
            '21/11/1999', // Date of Birth
            '2323', // Report To (Please enter employee Id)
            'VSE', // Designation
            'Akola-1 (pool)', // Reporting HQ
            'Maharashtra', // State
            '', //Zone
            '03/08/2024', // Probation Period
            'Active', // Employee Status
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers, sampleRecord]);
        XLSX.utils.book_append_sheet(wb, ws, "Headers");
        const fileName = 'employees.xlsx';
        XLSX.writeFile(wb, fileName);
    };

    useEffect(() => {
        if (formData.JoiningDate) {
            const joiningDate = new Date(formData.JoiningDate);
            const probationEndDate = new Date(joiningDate); // Create a new date object to avoid modifying the original
            probationEndDate.setDate(joiningDate.getDate() + 180); // Add 180 days

            // Format the date to dd/mm/yyyy
            const day = String(probationEndDate.getDate()).padStart(2, '0');
            const month = String(probationEndDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = probationEndDate.getFullYear();
            const formattedDate = `${day}/${month}/${year}`;

            setFormData((prevData) => ({ ...prevData, probationperiod: formattedDate })); // Update probationperiod
        }
    }, [formData.JoiningDate]); // Trigger on JoiningDate change



    return (
        <div>
            <div className="container-fluid mt-2">
                <div className="row mt-3 mb-4">
                    <div className="col-lg-8 col-md-6 col-sm-12 col-12 mb-3">
                        <BackButton title={"Create Employee"} />
                    </div>
                </div>
                <div className="markAttendance Approve_plansTab_heading">
                    <Tabs
                        defaultActiveKey="Pending"
                        id="fill-tab-example"
                        className="my-3 markAttendance_tab Approve_plansTab"
                        fill
                        style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
                    >
                        <Tab
                            eventKey="Pending"
                            title={"Multiple"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="" style={{ textAlign: "center" }}>
                                        Upload Excel File
                                    </h4>
                                    <div className="markAttendance_section container-fluid">
                                        <img alt="upload image" src={upload} style={{ height: '3rem' }} onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" />

                                        <input
                                            type="file"
                                            accept=".xlsx, .xls"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileUpload}
                                        />
                                        {fileError && <p className="error-message" style={{ color: 'red', textAlign: "center" }}>{fileError}</p>}
                                    </div>
                                    <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                                    <span className="leaveApply_button" onClick={handleSubmit}>
                                        <CommonButton title={"Submit file"} width={'18rem'} />
                                    </span>

                                    <div className='mt-2'>
                                        <span className="leaveApply_button" onClick={handleDownloadExcel}>
                                            <CommonButton title={"Download Excel"} width={'18rem'} />
                                        </span>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card style={{ width: "80%" }} className='mt-5 mb-5'>
                                <Card.Body>
                                    <div style={{ overflowX: 'auto' }} className='default-scrollbar'>
                                        <table className="text-center w-100">
                                            <thead>
                                                <tr className="ladder_heading">
                                                    <td className='pl-3 custom-header'>Employee Id</td>
                                                    <td className="custom-header">Message</td>
                                                    <td className="custom-header">Status</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {responseData.length > 0 ? (
                                                    responseData.map((employee) => (
                                                        <tr key={employee.empId} className="ladder_heading_data" style={{ height: '2.65rem', borderBottom: '1px solid lightblue', background: 'white', fontFamily: 'GilroyMedium' }}>
                                                            <td className='pl-3'>{employee.empId}</td>
                                                            <td className='pl-3'>{employee.message}</td>
                                                            <td className='pl-3'>{employee.status}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">No data available</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab
                            eventKey="Deleted"
                            title={"Single"}
                            className="markAttendance_tabsSection mt-4"
                            style={{ fontFamily: 'GilroyMedium' }}
                        >
                            <Card className="monthlyClaim_mobile">
                                <Card.Body>
                                    <h4 className="mb-4" style={{ textAlign: "center" }}>
                                        Create Employee
                                    </h4>
                                    <div className="formContainer">
                                        <form onSubmit={formSubmit}>
                                            <div className="form-row">
                                                {Object.keys(formData).map((field, index) => {
                                                    if (field === 'ReportTo') return null;

                                                    return (
                                                        <div className="form-group" key={field}>
                                                            <p className="PlanMTP_leftHead" htmlFor={field} style={{ textAlign: 'start' }}>
                                                                {fieldLabels[field]}
                                                            </p>
                                                            {field === 'DOB' || field === 'JoiningDate' ? (
                                                                <input
                                                                    className="CommonDropdown form-select"
                                                                    type="date"
                                                                    name={field}
                                                                    value={formData[field]}
                                                                    onChange={handleDateChange}
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                        backgroundImage: "none",
                                                                        paddingRight: "14px",
                                                                    }}
                                                                />
                                                            ) : field === 'EmployeeStatus' ? (
                                                                <CommonDropdown
                                                                    options={options}
                                                                    defaultOption={defaultOption}
                                                                    handleDropdownChange={(value) => setFormData({ ...formData, EmployeeStatus: value })}
                                                                    disableEdit={false}
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                    }}
                                                                />
                                                            ) : field === 'Region' ? (
                                                                <CommonDropdown
                                                                    options={regionoption}
                                                                    defaultOption={defaultregoption}
                                                                    handleDropdownChange={(value) => setFormData({ ...formData, Region: value })}
                                                                    disableEdit={false}
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                    }}
                                                                />
                                                            ) : field === 'Division' ? (
                                                                <CommonDropdown
                                                                    options={divisionoptions}
                                                                    defaultOption={defaultDivisionOption}
                                                                    handleDropdownChange={(value) => setFormData({ ...formData, Division: value })}
                                                                    disableEdit={false}
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                    }}
                                                                />
                                                            ) : field === 'probationperiod' ? ( // Check for probationperiod
                                                                <input
                                                                    className="CommonDropdown form-select"
                                                                    type="text"
                                                                    name="probationperiod"
                                                                    value={formData.probationperiod}
                                                                    readOnly // Set it as read-only
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                        backgroundImage: "none",
                                                                        paddingRight: "14px",
                                                                        backgroundColor: '#f0f0f0', // Optional: change background color
                                                                    }}
                                                                />
                                                            ) : (
                                                                <input
                                                                    className="CommonDropdown form-select"
                                                                    type="text"
                                                                    name={field}
                                                                    value={formData[field]}
                                                                    onChange={handleInputChange}
                                                                    style={{
                                                                        width: "100%",
                                                                        color: "rgb(46, 49, 146)",
                                                                        fontSize: "14px",
                                                                        backgroundImage: "none",
                                                                        paddingRight: "14px",
                                                                    }}
                                                                />
                                                            )}
                                                            {errors[field] && <p className="error-message">{errors[field]}</p>}
                                                        </div>
                                                    );
                                                })}

                                                {/* Add ReportTo field as the last field */}
                                                <div className="form-group">
                                                    <p className="PlanMTP_leftHead" htmlFor="ReportTo" style={{ textAlign: 'start' }}>
                                                        {fieldLabels['ReportTo']}
                                                    </p>
                                                    <input
                                                        className="CommonDropdown form-select"
                                                        type="text"
                                                        name="ReportTo"
                                                        value={formData.ReportTo}
                                                        onChange={handleInputChange}
                                                        style={{
                                                            width: "100%",
                                                            color: "rgb(46, 49, 146)",
                                                            fontSize: "14px",
                                                            backgroundImage: "none",
                                                            paddingRight: "14px",
                                                        }}
                                                    />
                                                    {errors['ReportTo'] && <p className="error-message">{errors['ReportTo']}</p>}
                                                </div>

                                            </div>
                                            <div style={{ marginTop: '1rem' }}>
                                                <span className="leaveApply_button" onClick={formSubmit}>
                                                    <CommonButton title={"Submit"} width={'18rem'} value="Submit" />
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            <ToastContainer />
            {loading && <Loader />}
        </div>
    );
};

export default CreateEmployeeComponent;
