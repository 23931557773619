import React, { useEffect, useState, useRef } from "react";
import "./LeaveApplyComponent.css";
import FailButton from "../Common/FailButton";
import BackButton from "../Common/BackButton";
import CommonButton from "../Common/CommonButton";
import { Helmet } from 'react-helmet';
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import "../Common/CommonDropdown.css";
import { sessionOptions, dayOptions } from "../Common/Constants";
import { leavesApplyApi } from '../services/leaves/leavesApi';
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../Common/Loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { getLeaveListAPi } from "../services/leaves/getLeaveListApi";
import { format, differenceInDays, addDays, } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import check from "../assets/images/check-mark-3280.svg";
import question from "../assets/images/red-question-11802.svg";
import draft from "../assets/images/draft.png";
import remove from "../assets/images/remove.png";
import { deleteLeaveApi } from "../services/leaves/deleteLeaveApi";
import { sendForApprovalLeave } from "../services/leaves/sendForApprovalLeave";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { leaveCount } from "../services/leaves/managerLeaveApprove"
import { formatDateStringDDMMYYYY } from "../Common/functions";

const LeaveApplyComponent = ({ options, defaultOption }) => {
  const [formData, setFormData] = useState({
    leaveType: "",
    fromDate: "",
    toDate: "",
    session1: "",
    session1DayType: "",
    session2: "",
    session2DayType: "",
    reason: "",
    isHalfDay: "",
    isFullDay: ""
  });
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [errors, setErrors] = useState({});
  const [fileName, setFileName] = useState();
  const [photoError, setPhotoError] = useState();
  const [imageData, setImageData] = useState();
  const [fileType, setFileType] = useState();
  const [SLCount, setSLCount] = useState(0);
  const [PLCount, setPLCount] = useState(0);
  const [CLCount, setCLCount] = useState(0)
  const [applyDays, setApplyDays] = useState(0)
  const accessToken = useSelector((state) => state?.accessToken?.accessToken);
  const empId = useSelector((state) => state?.userDetails?.details?.Id);
  const reportsId = useSelector((state) => state?.userDetails?.details?.ReportsToId);
  const empName = useSelector((state) => state?.userDetails?.details?.Employee_Name__c);
  const probationPeriod = useSelector((state) => state?.userDetails?.details?.Probation_period__c);
  const resignedStatus = useSelector((state) => state?.userDetails?.details?.Employee_Status__c);
  const sickLeave = useSelector((state) => state?.userDetails?.details?.SL__c);
  const privilegeLeave = useSelector((state) => state?.userDetails?.details?.PL__c);
  const casualLeave = useSelector((state) => state?.userDetails?.details?.CL__c);
  const festivalHoliday = useSelector((state) => state?.festivalHolidayList?.festivalHolidayDetails);
  const [isLoading, setIsLoading] = useState(false);


  const currentDate = new Date();
  const probationPeriodDate = new Date(probationPeriod);

  // const leaveTypeOptions = (currentDate < probationPeriodDate) || resignedStatus === "Resigned"
  //   ? ['Select Leave Type', 'Sick Leave']
  //   : ['Select Leave Type', 'Sick Leave', 'Casual Leave', 'Privilege Leave'];
  const leaveTypeOptions = (() => {
    const options = ['Select Leave Type'];

    if (SLCount > 0) {
      options.push('Sick Leave');
    }
    if (CLCount > 0) {
      options.push('Casual Leave');
    }
    if (PLCount > 0) {
      options.push('Privilege Leave');
    }

    if (currentDate < probationPeriodDate || resignedStatus === "Resigned") {
      return options.filter(option => option === 'Select Leave Type' || option === 'Sick Leave');
    }

    return options;
  })();


  const fileInputRef = useRef(null);
  const handlePhotoIconClick = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.leaveType || formData.leaveType === "Select Leave Type") {
      newErrors.leaveType = "Please select a valid leave type";
    }
    if (!formData.fromDate) {
      newErrors.fromDate = "Please select a from date";
    }
    if (!formData.toDate) {
      newErrors.toDate = "Please select a to date";
    }
    if (!formData.session1 || formData.session1 === "Select Session") {
      newErrors.session1 = "Please select a valid session";
    }
    if (!formData.reason) {
      newErrors.reason = "Please enter a reason";
    }

    // Convert festivalHoliday list into an array of dates
    const festivalHolidays = festivalHoliday?.map(holiday => new Date(holiday.Date__c));

    // Restrict Casual Leave to a maximum of 3 days
    if (formData.leaveType === "Casual Leave" || formData.leaveType === "Privilege Leave") {
      const startDate = new Date(formData.fromDate);
      const endDate = new Date(formData.toDate);
      let totalDays = differenceInDays(endDate, startDate) + 1;

      // Check for Sandwich Rule (Sunday or Festival Holiday between fromDate and toDate)
      let hasHolidayBefore = false;
      let hasHolidayAfter = false;

      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        if (date.getDay() === 0 || festivalHolidays?.some(holiday => holiday.getTime() === date.getTime())) {
          if (date.getTime() === startDate.getTime()) {
            hasHolidayBefore = true;
          } else if (date.getTime() === endDate.getTime()) {
            hasHolidayAfter = true;
          } else {
            hasHolidayBefore = true;
            hasHolidayAfter = true;
          }
        }
      }

      // Error messages for Casual Leave
      if (formData.leaveType === "Casual Leave") {
        if (totalDays >= 3) {
          newErrors.leaveType = "Casual Leave cannot exceed 3 days.";
        } else if (hasHolidayBefore && hasHolidayAfter) {
          newErrors.leaveType = "Casual Leave cannot be taken both before and after a Sunday or festival holiday.";
        }
      }

      // Error messages for Privilege Leave
      if (formData.leaveType === "Privilege Leave") {
        // Adjust the conditions for Privilege Leave, for example:
        // if (totalDays > 3) {
        //   newErrors.leaveType = "Privilege Leave cannot exceed 3 days.";
        // } else
        if (hasHolidayBefore && hasHolidayAfter) {
          newErrors.leaveType = "Privilege Leave cannot be taken both before and after a Sunday or festival holiday.";
        }
        else if (applyDays > PLCount) {
          newErrors.leaveType = `You cann not apply more than ${PLCount} days`;
        }
      }
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onChangeData = (e) => {
    const { name, value } = e.target;

    // Update formData state
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Update error state
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      switch (name) {
        case "leaveType":
          if (value !== "Select Leave Type" && value !== "") {
            delete newErrors.leaveType;
          }
          break;
        case "fromDate":
          if (value) {
            delete newErrors.fromDate;
          }
          // Validate toDate
          if (formData.toDate && new Date(value) > new Date(formData.toDate)) {
            newErrors.toDate = "To Date cannot be before From Date.";
          } else {
            delete newErrors.toDate;
          }
          break;
        case "toDate":
          if (value) {
            delete newErrors.toDate;
          }
          // Validate fromDate
          if (formData.fromDate && new Date(value) < new Date(formData.fromDate)) {
            newErrors.toDate = "To Date cannot be before From Date.";
          } else {
            delete newErrors.toDate;
          }
          break;
        case "session1":
          if (value !== "Select Session" && value !== "") {
            delete newErrors.session1;
          }
          break;
        case "session2":
          if (value !== "Select Session" && value !== "") {
            delete newErrors.session2;
          }
          break;
        case "reason":
          if (value.trim() !== "") {
            delete newErrors.reason;
          }
          break;
        default:
          break;
      }
      return newErrors;
    });
  };

  const getList = async () => {
    const response = await getLeaveListAPi(accessToken, empId)
    if (response?.data?.done === true) {
      setLeaveList(response?.data?.records)
    }
  }
  useEffect(() => {
    (async () => {
      await getList();
      const response = await leaveCount(accessToken, empId)
      console.log("resp", response?.data?.records)
      setCLCount(response?.data?.records?.[0]?.CL__c);
      setPLCount(response?.data?.records?.[0]?.PL__c);
      setSLCount(response?.data?.records?.[0]?.SL__c)
    })();
  }, [empId, accessToken]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'image/jpg') {
        setPhotoError('Please select a JPG or PNG file.');
        return;
      }
    }
    if (file) {
      setPhotoError("")
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        const imageData = base64String?.split(',')[1];
        const imageType = file?.type;
        const imageExtension = imageType?.split('/').pop().toLowerCase()
        setImageData(imageData);
        setFileType(imageExtension);
        setFileName(file.name)
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const startDate = new Date(formData.fromDate);
    const endDate = new Date(formData.toDate);
    const totalDays = differenceInDays(endDate, startDate) + 1;

    // Check if sick leave exceeds 3 days and attachment is missing
    if (formData.leaveType === "Sick Leave" && totalDays >= 3 && !imageData) {
      setPhotoError("Attachment is required for sick leave of 3 or more days.");
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const startDate = new Date(formData.fromDate);
        const endDate = new Date(formData.toDate);
        const totalDays = differenceInDays(endDate, startDate) + 1;

        let leaveData = [];

        for (let i = 0; i < totalDays; i++) {
          const currentDate = addDays(startDate, i);

          let currentLeaveData = {
            Reason__c: formData.reason,
            Name: empName,
            userId: empId,
            managerId: reportsId,
            leaveStartDate: format(currentDate, "yyyy-MM-dd"),
            leaveEndDate: format(currentDate, "yyyy-MM-dd"),
            fileData: imageData,
            fileType: fileType
          };

          if (totalDays > 1) {
            if (i === 0) {
              if (formData?.session1 === "Full Day") {
                console.log("First day is full-day");
                currentLeaveData.isFullDay = true;
                currentLeaveData.isHalfday = false;
                currentLeaveData.session1 = "Full Day";
                currentLeaveData.session2 = "Full Day";
              }
              if (formData.session1 === "Half Day") {
                console.log("First day is half-day");
                currentLeaveData.isHalfday = true;
                currentLeaveData.isFullDay = false;
                currentLeaveData.session1 = formData?.session1DayType
                currentLeaveData.session2 = formData?.session1DayType

              }
              else {
                currentLeaveData.isFullDay = true;
                currentLeaveData.isHalfday = false;
                currentLeaveData.session1 = "Full Day";
                currentLeaveData.session2 = "Full Day";
              }

            } else if (i === totalDays - 1) {
              if (formData?.session2 === "Half Day") {
                console.log("Last day is half-day");
                currentLeaveData.isHalfday = true;
                currentLeaveData.isFullDay = false;
                currentLeaveData.session1 = formData?.session2DayType
                currentLeaveData.session2 = formData?.session2DayType

              }
              if (formData?.session2 === "Full Day") {
                console.log("Last day is full-day");
                currentLeaveData.isHalfday = false;
                currentLeaveData.isFullDay = true;
                currentLeaveData.session1 = "Full Day";
                currentLeaveData.session2 = "Full Day";
              }
            } else {
              console.log("Subsequent day is full-day");
              currentLeaveData.isFullDay = true;
              currentLeaveData.isHalfday = false;
              currentLeaveData.session1 = "Full Day";
              currentLeaveData.session2 = "Full Day";
            }
          } else {
            if (formData.session1 === "Full Day") {
              console.log("Single day is full-day");
              currentLeaveData.isFullDay = true;
              currentLeaveData.isHalfday = false;
              currentLeaveData.session1 = "Full Day";
              currentLeaveData.session2 = "Full Day";
            } else if (formData.session1 === "Half Day") {
              console.log("Single day is half-day");
              currentLeaveData.isHalfday = true;
              currentLeaveData.isFullDay = false;
              currentLeaveData.session1 = formData?.session1DayType
              currentLeaveData.session2 = formData?.session1DayType
            }
          }

          if (formData.leaveType === "Sick Leave") {
            currentLeaveData.leaveType = "SL";
          } else if (formData.leaveType === "Casual Leave") {
            currentLeaveData.leaveType = "CL";
          } else if (formData.leaveType === "Privilege Leave") {
            currentLeaveData.leaveType = "PL";
          }
          leaveData.push(currentLeaveData);
        }
        const response = await leavesApplyApi(accessToken, leaveData);
        console.log("response", response);

        // Check the response message to decide whether to show a success or error toast
        const message = response?.data?.trim();
        if (response?.status === 200) {
          if (message === "Leave applications submitted successfully.") {
            toast.success(message);
            resetForm();
          } else if (message.includes("A leave application already exists")) {
            toast.error("A leave application already exists for this period.");
            resetForm();
          } else {
            toast.error(message); // Show error message for other cases
          }
        }
      } catch (err) {
        console.error(err);
        toast.error("An error occurred");
      } finally {
        setLoading(false);
        getList();
      }
    })();
  };



  const handleDelete = async (data) => {
    const response = await deleteLeaveApi(accessToken, data);
    if (response?.status === 204) {
      toast.success("Leave application deleted successfully");
      getList();
    }
    else {
      toast.error("An error occured")
    }
    console.log("response", response)
  }

  const handleApproveClick = async (data) => {
    setLoading(true);
    console.log("data", data)
    const recordId = data?.Id
    const response = await sendForApprovalLeave(accessToken, empId, recordId);
    console.log("response", response)
    if (response?.data?.trim() === "Leave requests updated successfully") {
      toast.success("Leave application sent for approval")
    }
    else {
      toast.error("An error occured")
    }
    getList();
    setLoading(false);
  }

  useEffect(() => {
    const fromDate = new Date(formData?.fromDate);
    const toDate = new Date(formData?.toDate);

    let differenceInDays = 0;

    for (let date = fromDate; date <= toDate; date.setDate(date.getDate() + 1)) {
      // Check if the day is not Sunday (0 = Sunday)
      if (date.getDay() !== 0) {
        differenceInDays++;
      }
    }

    // If the dates are the same, handle half-day logic
    if (fromDate.getTime() === toDate.getTime()) {
      if (formData?.session1 === "Half Day" || formData?.session2 === "Half Day") {
        differenceInDays = 0.5;
      } else {
        differenceInDays = 1;
      }
    } else {
      // Adjust the total days based on session1 and session2
      if (formData?.session1 === "Half Day") {
        differenceInDays -= 0.5;
      }

      if (formData?.session2 === "Half Day" && differenceInDays > 1) {
        differenceInDays -= 0.5;
      }
    }

    setApplyDays(differenceInDays);
  }, [formData]);


  function areDatesEqual(date1, date2) {
    console.log(date1, date2)
    const newdate1 = new Date(date1)
    const newdate2 = new Date(date2)

    return (
      newdate1.getFullYear() === newdate2.getFullYear() &&
      newdate1.getMonth() === newdate2.getMonth() &&
      newdate1.getDate() === newdate2.getDate()
    );
  }
  const resetForm = () => {
    setFormData({
      leaveType: "",
      fromDate: "",
      toDate: "",
      session1: "",
      session2: "",
      reason: "",
      session1DayType: "",
      session2DayType: ""
    });
    setFileType("");
    setImageData("");
    setFileName("");
  };
  return (
    <>
      <Helmet>
        <title>Leave Apply</title>
      </Helmet>
      <div className="container-fluid mt-2">
        <BackButton title={'Apply for leave'} />
        <div className="markAttendance Approve_plansTab_heading">
          <Tabs
            defaultActiveKey="Pending"
            id="fill-tab-example"
            className="my-3 markAttendance_tab Approve_plansTab"
            fill
            style={{ display: "flex", fontFamily: 'GilroyMedium', fontSize: '16px' }}
          >
            <Tab
              eventKey="Pending"
              title={"Apply"}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              <div className="markAttendance_section container-fluid mt-5">
                <div className="row mb-5">
                  <Card className="monthlyClaim_mobile">
                    <Card.Body>
                      <h4 className="mb-4" style={{ textAlign: "center" }}>
                        Applying For leave
                      </h4>

                      <h6 className="mb-4" style={{ textAlign: "center" }}>
                        This leave is applicable for the period January {new Date().getFullYear()} to December {new Date().getFullYear()}
                      </h6>
                      <div style={{ display: "flex", justifyContent: "space-evenly", color: "#7f8fa4" }}>
                        <span>Casual Leave : {CLCount}</span>
                        <span>Privilege Leave : {PLCount}</span>
                        <span>Sick Leave : {SLCount}</span>
                      </div>
                      <div className="row mt-3">
                        <div className="">
                          <Form.Select
                            aria-label="Default select example"
                            className="leaveDropdown PlanMTP_leftDrop"
                            value={formData?.leaveType}
                            name="leaveType"
                            onChange={(e) => { onChangeData(e) }}
                            style={{ fontFamily: 'GilroyMedium' }}

                          >
                            {leaveTypeOptions?.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                        </div>
                        {errors.leaveType && <p className="text-danger d-flex justify-content-start">{errors.leaveType}</p>}
                      </div>

                      {/* from date */}
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>Select From Date</p>
                          <input
                            className="CommonDropdown form-select"
                            type="date"
                            name="fromDate"
                            value={formData?.fromDate}
                            style={{
                              width: "100%",
                              color: "rgb(46, 49, 146)",
                              fontSize: "14px",
                              backgroundImage: "none",
                              paddingRight: "14px",
                            }}
                            onChange={(e) => onChangeData(e)}
                          />
                          {errors.fromDate && <p className="text-danger">{errors.fromDate}</p>}
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 leave-apply-select mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>Select Half Day/ Full Day</p>
                          <Form.Select
                            aria-label="Default select example"
                            className="leaveDropdown PlanMTP_leftDrop"
                            value={formData?.session1}
                            name="session1"
                            onChange={(e) => { onChangeData(e) }}
                            style={{ fontFamily: 'GilroyMedium' }}
                          >
                            {sessionOptions?.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                          {errors.session1 && <p className="text-danger">{errors.session1}</p>}
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-6 leave-apply-select mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>For</p>
                          <Form.Select
                            aria-label="Default select example"
                            className="leaveDropdown PlanMTP_leftDrop"
                            value={formData?.session1DayType}
                            name="session1DayType"
                            onChange={(e) => { onChangeData(e) }}
                            style={{ fontFamily: 'GilroyMedium' }}
                            disabled={formData?.session1 === 'Full Day'}
                          >
                            {formData?.session1 === "Half Day" && dayOptions?.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                          {errors.session1DayType && <p className="text-danger">{errors.session1DayType}</p>}
                        </div>

                      </div>

                      {/* To date  */}
                      {/* {areDatesEqual(formData?.fromDate, formData?.toDate) ? }÷ */}
                      <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-sm-6 mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>Select To Date</p>
                          <input
                            className="CommonDropdown form-select"
                            type="date"
                            name="toDate"
                            value={formData?.toDate}
                            style={{
                              width: "100%",
                              color: "rgb(46, 49, 146)",
                              fontSize: "14px",
                              backgroundImage: "none",
                              paddingRight: "14px",
                            }}
                            onChange={(e) => onChangeData(e)}
                          />
                          {errors.toDate && <p className="text-danger">{errors.toDate}</p>}
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 leave-apply-select mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>Select Half Day/ Full Day</p>
                          <Form.Select
                            aria-label="Default select example"
                            className="leaveDropdown PlanMTP_leftDrop"
                            value={formData?.session2}
                            name="session2"
                            onChange={(e) => { onChangeData(e) }}
                            style={{ fontFamily: 'GilroyMedium' }}
                            disabled={areDatesEqual(formData?.fromDate, formData?.toDate)}
                          >
                            {sessionOptions?.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                          {errors.session2 && <p className="text-danger">{errors.session2}</p>}
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-6 leave-apply-select mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>For</p>
                          <Form.Select
                            aria-label="Default select example"
                            className="leaveDropdown PlanMTP_leftDrop"
                            value={formData?.session2DayType}
                            name="session2DayType"
                            onChange={(e) => { onChangeData(e) }}
                            style={{ fontFamily: 'GilroyMedium' }}
                            disabled={areDatesEqual(formData?.fromDate, formData?.toDate) || formData?.session2 === 'Full Day'}
                          >
                            {formData?.session2 === "Half Day" && dayOptions?.map((option) => {
                              return <option value={option} style={{ fontFamily: 'GilroyMedium' }}>{option}</option>;
                            })}
                          </Form.Select>
                          {errors.session2DayType && <p className="text-danger">{errors.session2DayType}</p>}
                        </div>

                      </div>

                      {/* Enter reason  */}
                      <div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-sm-6 LAC_colButton mt-4">
                          <p className="PlanMTP_leftHead" style={{ textAlign: 'left' }}>Enter Reason</p>
                          <input
                            className="CommonDropdown form-select"
                            type="text"
                            name="reason"
                            value={formData?.reason}
                            style={{
                              width: "100%",
                              color: "rgb(46, 49, 146)",
                              fontSize: "14px",
                              backgroundImage: "none",
                              paddingRight: "14px",
                            }}
                            onChange={(e) => onChangeData(e)}
                          />
                          {errors.reason && <p className="text-danger d-flex justify-content-start">{errors.reason}</p>}
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="mt-4 PlanMTP_leftHead">Select Photograph</p>
                            <PhotoCameraIcon onClick={handlePhotoIconClick} className="mt-4 PlanMTP_leftHead ml-4" style={{ color: '#00a87e', fontSize: 30 }} />
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              ref={fileInputRef}
                              onChange={handleFileChange}
                            />
                            <p className="mt-4 PlanMTP_leftHead ml-4">{fileName}</p>
                          </div>
                          <div className="text-danger mt-2" style={{ display: 'flex', alignItems: 'start' }}>{photoError}</div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p className="mt-4 PlanMTP_leftHead">Total Days: {applyDays ? applyDays : 0}</p>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: '3rem', marginBottom: '1rem' }}>
                        <div className="col-md-6 text-center">
                          <span className="leaveApply_button" onClick={handleSubmit}>
                            <CommonButton title={"Save"} width={'18rem'} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center cancelBtn_mob">
                          <Link to={"/home"}>
                            <FailButton buttonText={"Cancel"} style={{ width: '100% !important' }} width={'18rem'} />
                          </Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="Deleted"
              title={"History"}
              className="markAttendance_tabsSection mt-4"
              style={{ fontFamily: 'GilroyMedium' }}
            >
              {leaveList?.map((data, index) => (
                <div className="MTP_mainContent mt-4" key={index}>
                  <Card>
                    <Card.Body style={{ padding: "10px" }}>
                      <div className="row">
                        <div
                          className="col-lg-1 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          From Date{" "}
                          <p className="MTP_data">
                            {formatDateStringDDMMYYYY(data?.Leave_Start_Date__c)}
                          </p>
                        </div>

                        <div
                          className="col-lg-1 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          To Date{" "}
                          <p className="MTP_data">
                            {formatDateStringDDMMYYYY(data?.Leave_End_Date__c)}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          Leave Type{" "}
                          <p className="MTP_data">
                            {data?.Leave_Type__c}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          Half Day{" "}
                          <p className="MTP_data">
                            {data?.Session_1__c}
                          </p>
                        </div>

                        <div
                          className="col-lg-1 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          Full Day{" "}
                          <p className="MTP_data">
                            {data?.Session_2__c}
                          </p>
                        </div>

                        <div
                          className="col-lg-2 col-md-2 col-6 MTP_tableHeading"
                          style={{ fontWeight: "700" }}>
                          Status{" "}
                          <div className="MTP_data">
                            {data?.Status__c === "Pending For Approval" ? (
                              <img
                                className="MTP_approved"
                                src={question}
                                alt=""
                              />
                            ) : data?.Status__c === "Saved" ? (
                              <img className="MTP_draft" src={draft} alt="" />
                            ) : data?.Status__c === "Rejected" ? (
                              <img
                                className="MTP_draft mr-1"
                                src={remove}
                                alt=""
                              />
                            ) : (
                              <img
                                className="MTP_approved"
                                src={check}
                                alt=""
                              />
                            )}
                            <span style={{ color: "black", fontWeight: "500" }}>
                              {data?.Status__c}
                            </span>
                            {/* <p className="MTP_tableData">{data?.Date__c}</p> */}
                          </div>
                          {/* <p className="MTP_data">
                            {data?.Status__c}
                          </p> */}
                        </div>

                        <div className="col-lg-2 col-md-2 col-6 mt-2">
                          {data?.Status__c === 'Saved' &&
                            <span onClick={() => handleApproveClick(data)}>
                              <CommonButton
                                title={"Send For Approval"}
                                width={"10rem"}
                              />
                            </span>
                          }
                        </div>

                        <div className="col-lg-1 col-md-2 col-6 mt-2 text-center">
                          {data?.Status__c === "Saved" && (
                            <span onClick={() => handleDelete(data?.Id)}>
                              <DeleteIcon className="MTP_Delete" />
                            </span>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </Tab>
          </Tabs>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loader />}
    </>
  );
};

export default LeaveApplyComponent;
